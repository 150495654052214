import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { videos } from '../data/videos';
import { LinkToVideo } from '../components/link-to-video';
import { Container } from '../components/container';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <Seo title="Accueil" />
    <div className="py-12 mt-8 flex flex-col items-center">
      <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />

      <h1 className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-800">
          Anne Marie Sow
        </span>
      </h1>
    </div>

    <Container className="mt-8 pb-12" maxWidth="4xl">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl tracking-tight font-extrabold sm:text-4xl">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-800">
            Mes dernières vidéos
          </span>
        </h2>
        <Link
          to="videos"
          className="whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-primary-400 to-primary-800 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-primary-600 hover:to-primary-900"
        >
          Voir tout
        </Link>
      </div>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
        {videos.slice(0, 3).map((video, key) => (
          <LinkToVideo key={key} to={video.to} imageSrc={video.imageSrc} title={video.title} />
        ))}
      </div>
    </Container>
  </Layout>
);

export default IndexPage;
